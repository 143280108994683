<template>
  <b-modal
    ref="switchAreaModal"
    v-model="showModal"
    hide-footer
    :title="$t('menu.areaChange')"
  >
    <div class="d-flex flex-column align-items-center flex-wrap mr-1">
      <SwitchAreaDropdowns
        :target-area="targetArea"
        @closeSwitchAreaModal="onCloseModal"
      />
    </div>
  </b-modal>
</template>

<script>
import { bus } from "@/main";
import MenuService from "@/core/services/menu.service";
import ApiService from "@/core/services/api.service";
import SwitchAreaDropdowns from "@/components/SYS/reactiveComponents/SwitchAreaDropdowns.vue";

export default {
  name: "SwitchArea",
  components: {
    SwitchAreaDropdowns
  },
  data: function () {
    return {
      routeBusy: false,
      items: {},
      showModal: false,
      targetArea: null
    };
  },
  mounted() {
    this.items = MenuService.getItems();

    bus.$on("menuAreaChanged", this.onMenuAreaChanged);
    bus.$on("subMenuAreaChanged", this.onSubMenuAreaChanged);
    // on change area, set partner´s or tenant´s api key for requests
    bus.$on("areaChangedFinished", this.onAreaChangedFinished);
  },
  destroyed() {
    bus.$off("menuAreaChanged", this.onMenuAreaChanged);
    bus.$off("subMenuAreaChanged", this.onSubMenuAreaChanged);
    bus.$off("areaChangedFinished", this.onAreaChangedFinished);
  },
  methods: {
    onMenuAreaChanged(data) {
      const selectedPartner = this.$store.getters.selectedPartner;
      const selectedClient = this.$store.getters.selectedClient;
      const selectedProject = this.$store.getters.selectedProject;

      if (typeof data.name === "undefined") {
        if (this.$route.name !== "dashboard") {
          this.$router.push({ name: "dashboard" });
        }
        bus.$emit("areaChangedFinished", data);
        return;
      }
      this.targetArea = data;
      const routeName = this.items[data.id]["children"][0]["name"];

      //Show modal only if necessary
      if (this.targetArea.name === "areaPartner" && !selectedPartner && this.appVersion < 2) {
        this.showModal = true;
        return;
      }
      if (this.targetArea.name === "areaTenant" && !selectedClient && this.appVersion < 2) {
        this.showModal = true;
        return;
      }
      if (this.targetArea.name === "areaProject" && !selectedProject) {
        this.showModal = true;
        return;
      }

      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }

      bus.$emit("areaChangedFinished", data);
    },
    onSubMenuAreaChanged(data) {
      const selectedPartner = this.$store.getters.selectedPartner;
      const selectedClient = this.$store.getters.selectedClient;
      const selectedProject = this.$store.getters.selectedProject;
      const skipProject = this.$store.getters.skipProject;

      this.targetArea = data.area;
      let routeName = data.routeName;

      if (routeName === "" && data.area.name === "areaPartner" && this.appVersion < 2) {
        routeName = data.area.children[1].children[0].route;
      }

      if (routeName === "" && data.area.name === "areaTenant" && this.appVersion < 2) {
        routeName = data.area.children[3].children[0].route;
      }

      //Show modal only if necessary
      if (this.targetArea.name === "areaPartner" && !selectedPartner && this.appVersion < 2) {
        this.showModal = true;
        return;
      }
      if (this.targetArea.name === "areaTenant" && !selectedClient && this.appVersion < 2) {
        this.showModal = true;
        return;
      }
      if (
        this.targetArea.name === "areaProject" &&
        !selectedProject &&
        !skipProject
      ) {
        this.showModal = true;
        return;
      }

      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }

      bus.$emit("areaChangedFinished", data);
    },
    onAreaChangedFinished() {
      if (!this.targetArea) return;
      if (this.targetArea.name === "areaPartner") {
        ApiService.setHeader("x-api-key", this.$store.getters.apiTokenPartner);
      } else if (
        this.targetArea.name === "areaTenant" ||
        this.targetArea.name === "areaProject"
      ) {
        const selectedClient = this.$store.getters.selectedClient;

        if (selectedClient && selectedClient.api_token) {
          const token = "Bearer " + selectedClient.api_token;
          ApiService.setHeader("x-api-key", token);
        }
      }
    },
    onCloseModal(selectedArea) {
      this.showModal = false;
      bus.$emit("areaChangedFinished", selectedArea);
    }
  }
};
</script>
