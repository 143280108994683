<template>
  <div
    v-if="isAuthenticated"
    class="d-flex flex-column flex-root"
    style="height: 100vh"
  >
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" :logo="loaderLogo"></Loader>
    <LoaderTransparent></LoaderTransparent>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div
        id="kt_wrapper"
        class="d-flex flex-column flex-row-fluid wrapper"
        :class="{ 'subheader-enabled': subheaderDisplay }"
      >
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-show="subheaderDisplay"
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid mt-2">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <KTScrollTop></KTScrollTop>
    <SwitchArea></SwitchArea>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/components/SYS/layout/aside/Aside.vue";
import KTHeader from "@/components/SYS/layout/header/Header.vue";
import KTHeaderMobile from "@/components/SYS/layout/header/HeaderMobile.vue";
import KTFooter from "@/components/SYS/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/components/SYS/layout/subheader/Subheader.vue";
//import KTStickyToolbar from "@/components/SYS/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/components/SYS/layout/extras/ScrollTop";
import Loader from "@/components/SYS/content/Loader.vue";
import LoaderTransparent from "@/components/SYS/content/LoaderTransparent";
import SwitchArea from "@/components/SYS/layout/extras/SwitchArea";

import Auth from "@/components/General/Auth/auth";

import MenuService from "@/core/services/menu.service";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    //KTStickyToolbar,
    KTScrollTop,
    Loader,
    LoaderTransparent,
    SwitchArea
  },
  data() {
    return {
      isAuthenticated: false,
      showSubmenu: false
    };
  },
  watch: {
    $route() {
      this.checkSubmenuVisibility();
    }
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    this.checkAuth();
    MenuService.setDefaultItems();
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);

    this.checkSubmenuVisibility();
  },
  methods: {
    // check if current user is authenticated
    checkAuth() {
      let verified = Auth.verify();
      if (verified.success) {
        this.isAuthenticated = true;
      }
      if (!verified.success) {
        this.$router.push({ name: "login" });
      }
    },
    checkSubmenuVisibility() {
      // this.showSubmenu = this.$route.name !== "dashboard";
      this.showSubmenu = true;
    }
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle", "layoutConfig"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display") && this.showSubmenu;
    }
  }
};
</script>
