import store from "@/core/services/store";
import { UPDATE_LANGUAGE } from "@/core/services/store/profile.module";

const i18nService = {
  defaultLanguage: "en",

  languages: [
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg"
    },
    {
      lang: "de",
      name: "German",
      flag: process.env.BASE_URL + "media/svg/flags/162-germany.svg"
    }
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  async setActiveLanguage(lang) {
    await store.dispatch(UPDATE_LANGUAGE, lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return store.getters.language || this.defaultLanguage;
  }
};

export default i18nService;
