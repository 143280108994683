<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center pl-0 border-bottom"
    :class="headerClasses"
  >
    <!--begin::Logo-->

    <div
      id="kt_brand"
      ref="kt_brand"
      class="brand-logo px-6"
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <a href="/">
        <img
          alt="Logo"
          :src="headerLogo"
          style="max-height: 3.5vh !important"
        />
      </a>
    </div>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <button
        v-if="asideEnabled"
        id="kt_aside_mobile_toggle"
        class="btn p-0 burger-icon burger-icon-left"
      >
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Header Menu Mobile Toggle-->
      <!--button
        class="btn p-0 burger-icon ml-4"
        id="kt_header_mobile_toggle"
        ref="kt_header_mobile_toggle"
      >
        <span></span>
      </button-->
      <!--end::Header Menu Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <button
        id="kt_header_mobile_topbar_toggle"
        ref="kt_header_mobile_topbar_toggle"
        class="btn btn-hover-text-primary p-0 ml-2 btn-clean"
      >
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
          <inline-svg class="svg-icon" src="media/svg/icons/General/User.svg" />
          <!--end::Svg Icon-->
        </span>
      </button>
      <!--end::Topbar Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

export default {
  name: "KTHeaderMobile",
  components: {},
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      // return process.env.BASE_URL + this.layoutConfig("self.logo.dark");
      return process.env.BASE_URL + "media/logos/yedi_Logo_white.png";
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  }
};
</script>

<style lang="scss" scoped>
#kt_brand {
  background-color: #ff3554 !important;
}
</style>

<style>
.header-mobile .burger-icon span {
  background-color: #b5b5c3;
}

.header-mobile .btn .svg-icon svg g [fill] {
  fill: #b5b5c3;
}

.header-mobile .btn .active .svg-icon svg g [fill] {
  fill: red;
}

.header-mobile .btn.active .svg-icon svg g [fill],
.header-mobile .btn:hover .svg-icon svg g [fill] {
  fill: #ff3554;
}
</style>
