<template>
  <div style="width: 100%">
    <!--- Partner -->
    <b-dropdown
      v-if="selectedArea.name !== 'areaAdmin' && userType === 'admin' && appVersion < 2"
      size="md"
      :toggle-class="
        selectedPartner
          ? 'select-client-dropdown-btn topbar-item text-decoration-none p-0 truncate-2'
          : ''
      "
      class="select-client-dropdown y-dropdown ml-1 my-1 my-sm-0"
      :class="targetArea ? 'switcharea-modal-dropdown' : ''"
      variant="light"
    >
      <template v-if="busyPartners" #button-content>
        <span class="text-center">
          <b-spinner />
        </span>
      </template>
      <template v-else #button-content>
        <span v-if="selectedPartner" class="py-0 px-1 m-0">
          {{ selectedPartner.name }}<br />#{{ selectedPartner.number }}
        </span>
        <span v-else>
          {{ $t("topbar.partnerSelect") }}
        </span>
      </template>

      <b-dropdown-item-button @click="setPartner(null)">
        <strong>{{ $t("topbar.partnerNone") }} </strong>
      </b-dropdown-item-button>
      <div v-if="partners.length > 0">
        <b-dropdown-divider></b-dropdown-divider>
        <b-form-input
          id="searchPartners"
          v-model="searchTitle"
          :placeholder="$t('search.searchTerm')"
        >
        </b-form-input>
      </div>
      <b-dropdown-item
        v-for="(item, i) in filteredPartners"
        :key="i"
        :value="item.id"
        @click="setPartner(item)"
      >
        <span style="word-wrap: break-word; white-space: normal">
          {{ item.name }}<br />
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            #{{ item.number }}
          </span>
        </span>
      </b-dropdown-item>
    </b-dropdown>
    <!--- Client -->
    <b-dropdown
      v-if="
        selectedArea.name !== 'areaAdmin' &&
        appVersion < 2 &&
        (userType === 'admin' || userType === 'partner')
      "
      size="md"
      :toggle-class="
        selectedClient
          ? 'select-client-dropdown-btn topbar-item text-decoration-none p-0 truncate-2'
          : ''
      "
      class="select-client-dropdown y-dropdown ml-1 mb-1 my-sm-0"
      :class="targetArea ? 'switcharea-modal-dropdown' : ''"
      variant="light"
    >
      <template v-if="busyClients" #button-content>
        <span class="text-center">
          <b-spinner />
        </span>
      </template>
      <template v-else #button-content>
        <span v-if="selectedClient" class="py-0 px-1 m-0">
          {{ selectedClient.clientName }}<br />#{{ selectedClient.number }}
        </span>
        <span v-else>
          {{ $t("topbar.clientSelect") }}
        </span>
      </template>
      <b-dropdown-item-button @click="setClient(null)">
        <strong>{{ $t("topbar.clientNone") }} </strong>
      </b-dropdown-item-button>
      <div v-if="clients.length > 0">
        <b-dropdown-divider></b-dropdown-divider>
        <b-form-input
          id="searchClients"
          v-model="searchTitle"
          :placeholder="$t('search.searchTerm')"
        >
        </b-form-input>
      </div>
      <b-dropdown-item
        v-for="(item, i) in filteredClients"
        :key="i"
        :value="item.id"
        @click="setClient(item)"
      >
        <span style="word-wrap: break-word; white-space: normal">
          {{ item.name }}<br />
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            #{{ item.number }}
          </span>
        </span>
      </b-dropdown-item>
    </b-dropdown>
    <!--- Project -->
    <b-dropdown
      v-if="selectedArea.name === 'areaProject'"
      size="md"
      :toggle-class="
        selectedProject
          ? 'select-client-dropdown-btn topbar-item text-decoration-none p-0 truncate-2'
          : ''
      "
      class="select-client-dropdown y-dropdown ml-1 mb-1 my-sm-0"
      :class="targetArea ? 'switcharea-modal-dropdown' : ''"
      variant="light"
    >
      <template v-if="busyProjects" #button-content>
        <span class="text-center">
          <b-spinner />
        </span>
      </template>
      <template v-else #button-content>
        <span v-if="selectedProject" class="py-0 px-1 m-0">
          {{ selectedProject.name }}<br />#{{ selectedProject.short }}
        </span>
        <span v-else>
          {{ $t("topbar.projectSelect") }}
        </span>
      </template>
      <b-dropdown-item-button @click="setProject('')">
        <strong>{{ $t("topbar.projectNone") }} </strong>
      </b-dropdown-item-button>
      <div v-if="projects.length > 0">
        <b-dropdown-divider></b-dropdown-divider>
        <b-form-input
          id="searchProjects"
          v-model="searchTitle"
          :placeholder="$t('search.searchTerm')"
        >
        </b-form-input>
      </div>
      <b-dropdown-item
        v-for="(item, i) in filteredProjects"
        :key="i"
        :value="item.id"
        @click="setProject(item)"
      >
        <span style="word-wrap: break-word; white-space: normal">
          {{ item.name }}<br />
          <span class="text-muted mt-3 font-weight-bold font-size-sm">
            #{{ item.short }}
          </span>
        </span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { bus } from "@/main";
import { mapGetters } from "vuex";
import MenuService from "@/core/services/menu.service";
import {
  LOAD_CLIENTS,
  LOAD_PARTNERS,
  LOAD_PROJECTS,
  UPDATE_SELECTED_CLIENT,
  UPDATE_SELECTED_PARTNER,
  UPDATE_SELECTED_PROJECT
} from "@/core/services/store/switchArea.module";

export default {
  props: {
    targetArea: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      selectedArea: -1,
      items: {},
      searchTitle: "",

      skipRouteChangeProjects: [
        "projectWelcome",
        "projectIntegrationsOverview",
        "projectDynamicModelsDynamicModel",
        "projectWorkflows",
        "projectWorkflowsCreate",
        "projectMappings",
        "projectMappingsCreate",
        "projectReportingJournal",
        "projectReportingCommunication",
        "projectDataStore",
        "projectDataStructures"
      ]
    };
  },
  computed: {
    ...mapGetters([
      "busyPartners",
      "partners",
      "selectedPartner",
      "busyClients",
      "clients",
      "selectedClient",
      "busyProjects",
      "projects",
      "selectedProject",
      "userType"
    ]),
    filteredPartners() {
      return this.partners.filter(partner => {
        return partner.number.includes(this.searchTitle) ||
          partner.uuid.includes(this.searchTitle) ||
          partner.name.toLowerCase().includes(this.searchTitle.toLowerCase())
        });
    },
    filteredClients() {
      return this.clients.filter(client => {
        return client.number.includes(this.searchTitle) ||
          client.uuid.includes(this.searchTitle) ||
          client.name.toLowerCase().includes(this.searchTitle.toLowerCase())
;      });
    },
    filteredProjects() {
      return this.projects.filter(project => {
        return project.name.toLowerCase().includes(this.searchTitle.toLowerCase())
      });
    },
  },
  watch: {
    $route() {
      this.getActiveArea();
    },
    selectedProject() {
      if (
        !this.targetArea &&
        this.selectedProject &&
        this.selectedProject.autoSet
      )
        return;
      this.changeRoute("project");
    }
  },
  mounted() {
    this.items = MenuService.getItems();
    this.getActiveArea();

    if (!this.targetArea) {
      this.loadData();
    }
  },
  methods: {
    getActiveArea() {
      this.selectedArea = MenuService.getActiveArea() ?? {};
      if (this.targetArea) {
        this.selectedArea = this.targetArea;
      }
    },
    async setPartner(partner) {
      this.searchTitle = "";
      await this.$store.dispatch(UPDATE_SELECTED_PARTNER, partner);

      if (!partner) {
        let routeName = "dashboard";
        if (this.$route.name === routeName) {
          return;
        }
        this.$router.push({ name: routeName }).then(() => {
          bus.$emit("areaChangedFinished", {});
        });
        return;
      }

      if (this.selectedArea.name === "areaPartner") {
        this.changeRoute("partner");
      }
    },
    async setClient(client) {
      this.searchTitle = "";
      await this.$store.dispatch(UPDATE_SELECTED_CLIENT, client);

      if (!client && this.selectedArea.name !== "areaPartner") {
        let routeName = "dashboard";
        if (this.$route.name === routeName) {
          return;
        }
        this.$router.push({ name: routeName }).then(() => {
          bus.$emit("areaChangedFinished", {});
        });
        return;
      }

      if (this.selectedArea.name === "areaTenant" && client) {
        this.changeRoute("client");
      }
    },
    setProject(project) {
      this.searchTitle = "";
      if (!this.selectedProject && !project) {
        this.changeRoute("project");
        return;
      }
      this.$store.dispatch(UPDATE_SELECTED_PROJECT, project);
    },
    loadData() {
      if (this.appVersion >= 2) {
        this.$store.dispatch(LOAD_PROJECTS);
      } else {
        if (this.userType === "admin") {
          this.$store.dispatch(LOAD_PARTNERS);
        } else if (this.userType === "partner") {
          this.$store.dispatch(LOAD_CLIENTS);
        } else {
          this.$store.dispatch(LOAD_PROJECTS);
        }
      }
    },
    changeRoute(triggerName) {
      this.$emit("closeSwitchAreaModal", this.selectedArea);

      if (
        triggerName === "project" &&
        this.selectedArea.id === "0" &&
        !this.skipRouteChangeProjects.includes(this.$route.name)
      ) {
        this.$router.push({ name: "projectWelcome" }).then(() => {
          bus.$emit("areaChangedFinished", this.selectedArea);
        });
        return;
      }

      if (this.selectedArea.id && this.items[this.selectedArea.id]) {
        const routeName =
          this.items[this.selectedArea.id]["children"][0]["name"];
        if (this.$route.name === routeName) {
          return;
        }

        //Skip route change if project changed and current page is inside skipRouteChangeProjects
        if (
          triggerName === "project" &&
          this.skipRouteChangeProjects.includes(this.$route.name)
        ) {
          return;
        }

        this.$router.push({ name: routeName });
        return;
      }

      if (this.$route.name === "dashboard") {
        return;
      }

      this.$router.push({ name: "dashboard" }).then(() => {
        bus.$emit("areaChangedFinished", {});
      });
    }
  }
};
</script>

<style lang="scss">
.select-client-dropdown ul.dropdown-menu.show {
  width: 100%;
  padding: 10px;
  overflow: auto;
  max-height: 460px;
}

.select-client-dropdown-btn.btn.btn-primary.dropdown-toggle:after {
  color: white !important;
  position: absolute !important;
  padding-left: 50px !important;
}
</style>

<style>
.btn.btn-light {
  color: #266f92;
}

.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light:focus:not(.btn-text),
.btn.btn-light.focus:not(.btn-text) {
  color: #266f92;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #266f92;
  text-decoration: none;
  background-color: #f3f6f9;
}

.y-dropdown {
  max-height: 45px;
  width: 230px;
}

.switcharea-modal-dropdown {
  max-height: 45px;
  min-width: 230px;
  width: 100% !important;
  padding-bottom: 5px;
}

@media (max-width: 1120px) {
  .y-dropdown {
    width: 175px !important;
  }
}

@media (max-width: 600px) {
  #kt_subheader {
    border-top: none !important;
  }

  .dropdown-wrapper {
    width: 100%;
  }

  .y-dropdown {
    width: 100% !important;
    max-width: 100%;
  }
}
</style>
