<template>
  <!-- begin:: Header Topbars -->
  <div style="flex: 1">
    <div class="topbar">
      <!--begin: Dashboard link -->
      <div class="topbar-item">
        <b-link
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
          :class="windowWideEnough ? 'm-w-130' : ''"
          @click="onClickDashboard"
        >
          <i
            class="fa-light fa-objects-column icon-1x mr-2"
            :class="{ 'text-primary': !selectedArea.id }"
          ></i>
          <span
            v-if="windowWideEnough"
            :class="{ 'text-primary': !selectedArea.id }"
            >{{ $t("menu.welcome") }}</span
          >
        </b-link>
      </div>
      <!--end: Dashboard link -->

      <!--begin: Projects bar -->
      <div v-if="appVersion < 2" class="topbar-item">
        <b-link
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
          :class="windowWideEnough ? 'm-w-100' : ''"
          @click="selectMenu(0)"
        >
          <i
            :class="{ 'text-primary': selectedArea.id === '0' }"
            class="fa-light fa-clipboard-list-check mr-2"
          ></i>
          <span
            v-if="windowWideEnough"
            :class="{ 'text-primary': selectedArea.id === '0' }"
            >{{ $t("projects.title") }}</span
          >
        </b-link>
      </div>
      <!--end: Projects bar -->

      <v-spacer></v-spacer>

      <!--begin: Language bar -->
      <div class="topbar-item">
        <b-dropdown
          v-b-tooltip.bottom.noninteractive="$t('general.language')"
          size="sm"
          variant="link"
          toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
          no-caret
          right
          no-flip
        >
          <template #button-content>
            <img
              class="h-20px w-20px rounded-sm"
              :src="languageFlag || getLanguageFlag"
              alt=""
            />
          </template>
          <b-dropdown-text tag="div" class="min-w-md-175px">
            <KTDropdownLanguage
              @language-changed="onLanguageChanged"
            ></KTDropdownLanguage>
          </b-dropdown-text>
        </b-dropdown>
      </div>
      <!--end: Language bar -->

      <!--begin: Help bar -->
      <div class="topbar-item">
        <b-dropdown
          ref="helpDD"
          v-b-tooltip.bottom.noninteractive="$t('general.help')"
          size="sm"
          variant="link"
          toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
          no-caret
          right
          no-flip
        >
          <template #button-content>
            <i class="fa-light fa-comments-question"></i>
          </template>
          <b-dropdown-text tag="div" class="min-w-md-175px">
            <ul class="navi navi-hover p-0 py-2">
              <router-link
                v-for="(item, i) in helpArea"
                :key="i"
                v-slot="{ href, navigate, isActive, isExactActive }"
                :to="{ name: item.route }"
                custom
                @click.native="$refs.helpDD.hide(true)"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="navi-item"
                >
                  <a :href="href" class="navi-link" @click="navigate">
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </b-dropdown-text>
        </b-dropdown>
      </div>
      <!--end: Help bar -->

      <!--begin: Menu area select bar -->
      <div v-if="appVersion < 2" class="topbar-item">
        <b-dropdown
          ref="settingsDD"
          v-b-tooltip.bottom.noninteractive="$t('general.settings')"
          size="sm"
          variant="link"
          toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
          no-caret
          right
          no-flip
        >
          <template #button-content>
            <i class="fa-light fa-gear"></i>
          </template>
          <b-dropdown-text tag="div" class="min-w-md-300px">
            <ul class="navi navi-hover p-0 py-2">
              <a
                v-for="(item, key) in menu"
                v-show="item.show"
                :key="key"
                @click="
                  selectMenu(item.menuIndex);
                  $refs.settingsDD.hide(true);
                "
                @click.middle="selectMenu(item.menuIndex)"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="navi-item"
                >
                  <a
                    class="navi-link d-inline-flex align-items-start flex-grow-0 flex-shrink-0"
                  >
                    <v-icon class="w-24 flex-grow-0 flex-shrink-0">
                      {{ item.icon }}
                    </v-icon>
                    <div class="d-inline-flex flex-column menu-text ml-4">
                      <span class="" :class="{ 'text-primary': item.active }">
                        {{ $t(item.label) }}
                      </span>
                      <small :class="{ 'text-primary': item.active }">{{
                        $t(item.desc)
                      }}</small>
                    </div>
                  </a>
                </li>
              </a>
            </ul>
          </b-dropdown-text>
        </b-dropdown>
      </div>
      <!--end: Menu area select bar -->

      <!--begin: Profile bar -->
      <div class="topbar-item">
        <KTQuickUser />
      </div>
      <!--end: Profile bar -->

      <!--begin: Logout bar -->
      <div class="topbar-item">
        <b-link
          v-b-tooltip.bottom.noninteractive="$t('general.logout')"
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
          @click="logout"
        >
          <i class="fal fa-arrow-right-from-bracket"></i>
        </b-link>
      </div>
      <!--end: Logout bar -->

      <!--begin: Notifications
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="topbar-item text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <div
            class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
          >
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="media/svg/icons/Code/Compiling.svg" />
            </span>
            <span class="pulse-ring"></span>
          </div>
        </template>
        <b-dropdown-text tag="div" class="min-w-400px">
          <form>
            <KTDropdownNotification></KTDropdownNotification>
          </form>
        </b-dropdown-text>
      </b-dropdown>
      -->
    </div>
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import { bus } from "@/main";
import KTDropdownLanguage from "@/components/SYS/layout/extras/dropdown/DropdownLanguage.vue";
import Auth from "@/components/General/Auth/auth";
import KTQuickUser from "@/components/SYS/layout/extras/offcanvas/QuickUser.vue";
import menu from "@/core/config/menu.json";
import MenuService from "@/core/services/menu.service";
// import KTDropdownNotification from "@/components/SYS/layout/extras/dropdown/DropdownNotification.vue";

export default {
  name: "KTTopbar",
  components: {
    KTDropdownLanguage,
    KTQuickUser
    //KTDropdownNotification
  },
  data() {
    return {
      helpArea: [
        { name: "support", route: "helpSupport", label: "menu.helpSupport" },
        {
          name: "contactPerson",
          route: "helpContactPerson",
          label: "menu.helpContactPerson"
        }
      ],
      languageFlag: "",
      languages: i18nService.languages,
      clients: [],
      userType: null,
      selectedArea: {},
      windowWidth: 0
    };
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "selectedClient"]),
    windowWideEnough: function () {
      return this.windowWidth >= 500;
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    menu() {
      return [
        {
          menuIndex: 1,
          label: "menu.areaTenant",
          desc: "dashboard.tenantShortDescription",
          icon: "fal fa-users",
          show: this.userType === "admin" || this.userType === "partner" || this.userType === "client",
          active: this.selectedArea.name === "areaTenant"
        },
        {
          menuIndex: 2,
          label: "menu.areaPartner",
          desc: "dashboard.partnerShortDescription",
          icon: "fal fa-user-tie",
          show: this.userType === "admin" || this.userType === "partner",
          active: this.selectedArea.name === "areaPartner"
        },
        {
          menuIndex: 3,
          label: "menu.areaAdmin",
          desc: "dashboard.adminShortDescription",
          icon: "fal fa-key",
          show: this.userType === "admin",
          active: this.selectedArea.name === "areaAdmin"
        }
      ];
    }
  },
  watch: {
    selectedClient: function () {
      this.$mount();
    }
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    bus.$on("areaChangedFinished", this.onAreaChangedFinished);
    this.getActiveArea();

    let me = this;
    window.addEventListener("resize", function () {
      me.windowWidth = window.innerWidth;
    });
    this.windowWidth = window.innerWidth;
  },
  destroyed() {
    bus.$off("areaChangedFinished", this.onAreaChangedFinished);
  },
  methods: {
    onAreaChangedFinished(area) {
      this.selectedArea = area;
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    getActiveArea() {
      this.selectedArea = MenuService.getActiveArea() ?? {};
    },
    logout() {
      Auth.logout();
      this.$router.push({ name: "login" });
    },
    selectMenu(menuIndex) {
      bus.$emit("menuAreaChanged", menu[menuIndex]);
    },
    onClickDashboard() {
      bus.$emit("menuAreaChanged", {});
    }
  }
};
</script>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}

.btn.btn-primary.dropdown-toggle:after {
  color: white !important;
}

ul.dropdown-menu.dropdown-menu-right.show {
  padding: 10px;
}

.m-w-100 {
  min-width: 100px;
}
.m-w-130 {
  min-width: 130px;
}
</style>
