<template>
  <div style="height: 100%">
    <div class="pt-0 m-0 column h-100 d-flex flex-column">
      <div class="text-white pt-6 pb-2 pl-8 pr-8">
        <h5 v-if="selectedArea.id === '0'" class="area-label">
          {{ projectName }}
        </h5>
        <h5 v-else-if="selectedArea.label" class="area-label">
          {{ $t(selectedArea.label) }}
        </h5>
      </div>

      <div v-if="selectedArea.label" class="h-100 area-selected">
        <div class="h-100 d-flex flex-column">
          <ul
            v-for="(lev1, i) in selectedArea.children"
            :key="i"
            class="menu-nav py-0"
            :style="lev1.expand ? 'flex-grow: 1' : ''"
          >
            <v-divider
              v-if="lev1.divider"
              style="border-color: #ffffff40"
              class="m-0"
            />
            <!--   START::SUBMENU ITEM   -->
            <li
              v-if="Object.keys(lev1.children).length"
              v-b-tooltip.right.noninteractive="$t(lev1.label)"
              class="menu-item menu-item-submenu"
              :class="{
                'menu-item-active menu-item-open': hasActiveChild(lev1)
              }"
              @click="submenuClickHandler"
            >
              <a class="menu-link menu-toggle align-items-center">
                <v-icon
                  v-if="lev1.icon && hasActiveChild(lev1)"
                  class="menu-icon"
                  >{{ lev1.icon }}
                </v-icon>
                <v-icon
                  v-if="lev1.icon && !hasActiveChild(lev1)"
                  class="menu-icon"
                  >{{ lev1.icon }}
                </v-icon>

                <span v-if="hasActiveChild(lev1)" class="menu-text">{{
                  $t(lev1.label)
                }}</span>
                <span v-else class="menu-text">
                  {{ $t(lev1.label) }}
                </span>
                <i class="menu-arrow"></i>
              </a>
              <!--   START::SUBMENU ITEM TOGGLE   -->
              <div class="menu-submenu">
                <ul class="menu-subnav">
                  <router-link
                    v-for="(lev2, ii) in lev1.children"
                    :key="ii"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="{ name: lev2.route }"
                    custom
                  >
                    <li
                      v-b-tooltip.right.noninteractive="$t(lev2.label)"
                      aria-haspopup="true"
                      data-menu-toggle="active"
                      class="menu-item"
                      :class="{ 'menu-item-active': isActive || isExactActive }"
                    >
                      <a
                        :href="href"
                        class="menu-link align-items-center"
                        @click="navigate"
                      >
                        <v-icon
                          v-if="lev2.icon && isActive"
                          class="mr-2 menu-icon yedi-text-primary"
                        >
                          {{ lev2.icon }}
                        </v-icon>
                        <v-icon
                          v-if="lev2.icon && !isActive"
                          class="mr-2 menu-icon"
                        >
                          {{ lev2.icon }}
                        </v-icon>

                        <span
                          v-if="isActive"
                          class="menu-text yedi-text-primary"
                        >
                          {{ $t(lev2.label) }}
                        </span>
                        <span v-else class="menu-text">
                          {{ $t(lev2.label) }}
                        </span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
              <!--   END::SUBMENU ITEM TOGGLE   -->
            </li>
            <!--   END::SUBMENU ITEM   -->
            <!--   START::MENU ITEM   -->
            <router-link
              v-else
              v-slot="{ href, navigate, isActive, isExactActive }"
              data-menu-toggle="hover"
              :to="{ name: lev1.route }"
              custom
            >
              <li
                v-b-tooltip.right.noninteractive="$t(lev1.label)"
                class="menu-item"
                :class="{ 'menu-item-active': isActive || isExactActive }"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-icon v-if="lev1.icon && isActive" class="menu-icon">
                    {{ lev1.icon }}
                  </v-icon>
                  <v-icon v-if="lev1.icon && !isActive" class="menu-icon">
                    {{ lev1.icon }}
                  </v-icon>
                  <span v-if="isActive" class="menu-text">
                    {{ $t(lev1.label) }}
                  </span>
                  <span v-else class="menu-text">
                    {{ $t(lev1.label) }}
                  </span>
                  <v-icon v-if="lev1.link" class="menu-icon">
                    fal fa-arrow-up-right-from-square
                  </v-icon>
                </a>
              </li>
            </router-link>
            <!--   END::MENU ITEM   -->

            <v-divider
              v-if="lev1.dividerBottom"
              style="border-style: dashed; border-color: #ffffff"
              class="m-0"
            />
          </ul>
        </div>
      </div>
      <div v-else>
        <ul class="menu-nav py-0">
          <li
            v-for="(area, key) in availableAreas"
            :key="key"
            v-b-tooltip.right.noninteractive="area.label"
            class="menu-item menu-item-submenu"
          >
            <a
              class="menu-link menu-toggle align-items-center"
              @click="selectMenu(area.menuKey)"
            >
              <v-icon class="menu-icon">{{ area.icon }}</v-icon>
              <span class="menu-text">{{ area.label }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
body:not(.aside-minimize) .popover.b-popover.bs-popover-right {
  display: none !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  border: 0 !important;
}

.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border: 0 !important;
}

.aside {
  .v-select__selections {
    margin-left: 5px;
  }
}

.v-menu__content,
.aside {
  .v-icon {
    width: 20px;
    font-size: 20px !important;
  }
}

.aside-menu .menu-nav > .menu-item > .menu-heading,
.aside-menu .menu-nav > .menu-item > .menu-link {
  min-height: 54px !important;
}

.bottom {
  position: absolute;
  bottom: 52px;
  width: 100%;
}

/*
.v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
  border: 0 !important;
}

 */
</style>

<script>
import Auth from "@/components/General/Auth/auth";
import MenuService from "@/core/services/menu.service";
import { bus } from "@/main";
import menu from "@/core/config/menu.json";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle";
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  data() {
    return {
      userType: null,
      items: {},
      itemsArray: [],
      selectedArea: {},
      projectName: "",
      error: ""
    };
  },
  computed: {
    availableAreas: function () {
      let areas = [
        {
          icon: "fa-light fa-clipboard-list-check",
          label: this.$t("menu.areaProject"),
          menuKey: 0
        }
      ];

      if(this.appVersion < 2) {
        areas.push({
          icon: "fal fa-users",
          label: this.$t("menu.areaTenant"),
          menuKey: 1
        })
      }

      if (this.userType === "partner" || this.userType === "admin" && this.appVersion < 2) {
        areas.push({
          icon: "fal fa-user-tie",
          label: this.$t("menu.areaPartner"),
          menuKey: 2
        });
      }

      if (this.userType === "admin" && this.appVersion < 2) {
        areas.push({
          icon: "fal fa-key",
          label: this.$t("menu.areaAdmin"),
          menuKey: 3,
          requiredUserType: "admin"
        });
      }

      return areas;
    },
    ...mapGetters(["selectedProject"])
  },
  watch: {
    $route: function () {
      this.getActiveArea();
    },
    selectedProject: function () {
      this.setProjectName();
    }
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    this.getMenuItems();
    this.setProjectName();
  },
  methods: {
    onLogout() {
      Auth.logout();
      this.$router.push({ name: "login" });
    },
    getMenuItems() {
      this.items = MenuService.getItems();
      this.itemsArray = [];
      Object.keys(this.items).forEach(key => {
        let area = this.items[key];
        area.id = key;
        this.itemsArray.push(area);
      });
      this.getActiveArea();
    },
    getActiveArea() {
      this.selectedArea = MenuService.getActiveArea();
    },
    hasActiveChild(item) {
      let setActive = false;
      Object.keys(item.children).forEach(key => {
        if (this.$route.name.includes(item.children[key].route))
          setActive = true;
      });
      return setActive;
    },
    isHelpArea() {
      let isHelp = false;
      this.helpArea.forEach(item => {
        if (item.route === this.$route.name) isHelp = true;
      });
      return isHelp;
    },
    selectMenu(menuIndex) {
      bus.$emit("menuAreaChanged", menu[menuIndex]);
    },
    submenuClickHandler() {
      if (!document.body.classList.contains("aside-minimize")) {
        return;
      }
      try {
        KTLayoutAsideToggle.getToggle().toggle();
      } catch (e) {
        this.error = e;
      }
    },
    setProjectName() {
      this.projectName = this.selectedProject?.name ?? this.$t(this.selectedArea.label);
    }
  }
};
</script>

<style lang="scss" scoped>
.area-label {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  .aside-minimize & {
    opacity: 0;
  }
}
.menu-text {
  display: flex !important;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  .aside-minimize & {
    opacity: 0;
  }
}
</style>
