<template>
  <!-- begin:: Aside -->
  <div
    id="kt_aside"
    ref="kt_aside"
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
    @mouseover="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <!-- begin:: Aside -->
    <KTBrand></KTBrand>
    <!-- end:: Aside -->

    <!-- begin:: Aside Menu -->
    <div
      id="kt_aside_menu_wrapper"
      class="aside-menu-wrapper flex-column-fluid"
    >
      <div
        id="kt_aside_menu"
        ref="kt_aside_menu"
        class="aside-menu my-0"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        :class="asideMenuClass"
        style="height: 100%"
      >
        <perfect-scrollbar
          class="aside-menu scroll"
          style="max-height: 100vh; position: relative; height: 100%"
          :class="selectedArea.id === '0' ? 'scroll-project' : 'scroll-other'"
        >
          <KTMenu></KTMenu>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import { mapGetters } from "vuex";
import KTBrand from "@/components/SYS/layout/brand/Brand.vue";

import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTMenu from "@/components/SYS/layout/aside/Menu.vue";
import { bus } from "@/main";
import MenuService from "@/core/services/menu.service";

export default {
  name: "KTAside",
  components: {
    KTBrand,
    KTMenu
  },
  data() {
    return {
      selectedArea: {},
      scrollbarOptions: {
        wheelPropagation: false,
        suppressScrollX: true
      },
      insideTm: 0,
      outsideTm: 0
    };
  },
  mounted() {
    this.selectedArea = MenuService.getActiveArea();
    this.$nextTick().then(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);
    });

    bus.$on("menuAreaChanged", this.onMenuAreaChanged);
  },
  destroyed() {
    bus.$off("menuAreaChanged", this.onMenuAreaChanged);
  },
  methods: {
    onMenuAreaChanged(area) {
      this.selectedArea = area;
    },
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        // check if the left aside menu is fixed
        if (document.body.classList.contains("aside-fixed")) {
          if (this.outsideTm) {
            clearTimeout(this.outsideTm);
            this.outsideTm = null;
          }

          // if the left aside menu is minimized
          if (document.body.classList.contains("aside-minimize")) {
            document.body.classList.add("aside-minimize-hover");

            // show the left aside menu
            document.body.classList.remove("aside-minimize");
          }
        }
      }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        if (document.body.classList.contains("aside-fixed")) {
          if (this.insideTm) {
            clearTimeout(this.insideTm);
            this.insideTm = null;
          }

          if (document.querySelector(".aside-menu .scroll")) {
            document.querySelector(".aside-menu .scroll").scrollTop = 0;
          }

          // if the left aside menu is expand
          if (document.body.classList.contains("aside-minimize-hover")) {
            // hide back the left aside menu
            document.body.classList.remove("aside-minimize-hover");
            document.body.classList.add("aside-minimize");
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>

<style lang="scss">
#kt_aside {
  transition: width 0.3s ease-in-out;
}
#kt_aside_menu_wrapper {
  transition: margin-left 0.3s ease-in-out;
  .aside-minimize & {
    margin-left: -7px;
  }
}

/*.scroll-project {
  max-height: 90vh !important;
}
.scroll-other {
  max-height: 80vh !important;
}*/
</style>
