<template>
  <div
    id="kt_subheader"
    class="subheader py-2 py-lg-4"
    :class="subheaderClasses"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      :class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div
        v-if="title !== 'Welcome'"
        class="d-flex align-items-center flex-wrap mr-1"
      >
        <ul class="breadcrumb breadcrumb-dot breadcrumb-transparent p-0 my-2">
          <li
            v-if="
              breadcrumbs.length > 2 &&
              breadcrumbs[breadcrumbs.length - 2].route
            "
            class="breadcrumb-item text-muted"
            style="display: flex"
          >
            <router-link
              :to="breadcrumbs[breadcrumbs.length - 2].route"
              class=""
            >
              <i class="fal fa-caret-square-left breadcrumb-item-inactive"></i>
            </router-link>
          </li>
          <li class="breadcrumb-item text-muted" style="display: flex">
            <router-link
              :to="{ name: welcomeLink }"
              class="subheader-breadcrumbs-home"
            >
              <i
                class="fa-light fa-objects-column breadcrumb-item-inactive icon-1x"
              ></i>
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li
              :key="`${i}-${breadcrumb.id}`"
              class="breadcrumb-item breadcrumb-item-inactive"
              style="display: flex"
            >
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="breadcrumb-item-inactive"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span
                v-if="!breadcrumb.route"
                :key="i"
                class="breadcrumb-item-inactive"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div v-else></div>
      <div class="align-items-center flex-wrap mr-1 dropdown-wrapper">
        <SwitchAreaDropdowns />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SwitchAreaDropdowns from "@/components/SYS/reactiveComponents/SwitchAreaDropdowns";

export default {
  name: "KTSubheader",
  components: {
    SwitchAreaDropdowns
  },
  props: {
    breadcrumbs: Array,
    title: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },
    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
    welcomeLink() {
      if (this.selectedArea && this.selectedArea["children"]) {
        return this.selectedArea["children"]["0"]["route"];
      }

      return "dashboard";
    }
  },
  data() {
    return {
      userType: null,
      selectedArea: -1
    };
  },
  methods: {}
};
</script>

<style>
.btn.btn-light {
  color: #266f92;
}

.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light:focus:not(.btn-text),
.btn.btn-light.focus:not(.btn-text) {
  color: #266f92;
}

.y-dropdown {
  width: 230px;
}

@media (max-width: 1120px) {
  .y-dropdown {
    width: 175px !important;
  }
}
@media (max-width: 600px) {
  #kt_subheader {
    border-top: none !important;
  }

  .dropdown-wrapper {
    width: 100%;
  }

  .y-dropdown {
    width: 100% !important;
    max-width: 100%;
  }
}
</style>
