<template>
  <!-- begin:: Aside -->
  <div id="kt_brand" ref="kt_brand" class="brand flex-column-auto pr-0">
    <div class="brand-logo pt-4 pb-2 pr-6">
      <router-link to="/">
        <img :src="logoPath" class="img-fluid w-100 mh-100" alt="" />
      </router-link>
    </div>
    <div v-if="allowMinimize" class="brand-tools">
      <button
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
        class="brand-toggle btn btn-sm px-0"
      >
        <span class="svg-icon svg-icon svg-icon-xl">
          <inline-svg
            class="svg-icon"
            src="media/svg/icons/Navigation/Angle-double-left.svg"
          />
        </span>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

export default {
  name: "KTBrand",
  data() {
    return {
      logo: "media/logos/yedi-white.svg"
    };
  },
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },
    logoPath() {
      return process.env.BASE_URL + this.logo;
    }
  }
};
</script>

<style lang="scss" scoped>
#kt_brand {
  transition: all 0.3s ease-in-out;
  background-color: #ff3554;
  .aside-minimize & {
    flex-direction: column;
    padding: 0 15px !important;
    .brand-logo {
      padding: 0 !important;
      margin-top: 10px;
      img {
        display: block;
        width: 36px !important;
        height: 32px;
        object-fit: cover;
        object-position: left;
      }
    }
    #kt_aside_toggle {
      > .svg-icon {
        margin-right: -5px;
      }
    }
  }
}
</style>
