<template>
  <div class="topbar-item">
    <div
      id="kt_quick_user_toggle"
      ref="kt_quick_user_toggle"
      v-b-tooltip.bottom.noninteractive="$t('general.profile')"
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-3 mr-1"
    >
      <img
        :src="getProfilePicture"
        :alt="getFullName"
        width="20px"
        height="20px"
        class="rounded-circle"
      />
    </div>
    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0 yedi-text-green">
          {{ $t("users.profile") }}
        </h3>
        <a
          id="kt_quick_user_close"
          href="#"
          class="btn btn-xs btn-icon btn-square rounded btn-light btn-hover-primary"
        >
          <i class="fal fa-xmark text-muted"></i>
        </a>
      </div>
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img
              class="symbol-label"
              :src="getProfilePicture"
              alt=""
              @error="imageError = true"
            />
            <i
              class="symbol-badge"
              :class="
                $root.$refs.Pusher.pusher &&
                $root.$refs.Pusher.pusher.connection.state === 'connected'
                  ? 'bg-success'
                  : 'bg-danger'
              "
            >
            </i>
          </div>
          <div class="d-flex flex-column">
            <h3 class="yedi-text-green" style="text-transform: capitalize">
              {{ getFullName }}
            </h3>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2 text-muted text-hover-primary">
                  <span class="navi-icon mr-1">
                    <i class="fal fa-envelope" />
                  </span>
                  <span class="navi-text">
                    {{ currentUserPersonalInfo.email }}
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <div class="d-flex align-items-center mt-5">
          <b-row class="p-0 m-0">
            <b-col md="12" class="p-0 m-0">
              <div class="navi navi-active navi-hover navi-link-rounded">
                <router-link
                  v-for="(entry, i) in getMenuEntries"
                  :key="i"
                  :to="entry.route"
                  class="navi-item mb-2"
                  @click.native="closeOffcanvas"
                >
                  <div
                    class="navi-link py-4"
                    :class="{ active: $route.name === entry.route.name }"
                  >
                    <span class="navi-icon mr-1">
                      <i :class="entry.icon" />
                    </span>
                    <div class="navi-text">
                      <div class="font-weight-bold">
                        {{ $t(entry.label) }}
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="separator separator-dashed mt-4 mb-4"></div>
        <div class="d-flex align-items-center mt-5">
          <b-row class="p-0 m-0">
            <b-col md="12" class="p-0 m-0">
              <b-form-group id="input-group-5">
                <b-form-checkbox
                  v-model="expandedSidebarMenu"
                  switch
                  @change="expandedSidebarMenuSet"
                >
                  {{ $t("general.expandedMenu") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Auth from "@/components/General/Auth/auth";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import store from "@/core/services/store";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";

export default {
  name: "KTQuickUser",
  data() {
    return {
      imageError: false,
      expandedSidebarMenu: false
    };
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
    getProfilePicture() {
      let userData = this.$store.getters.userData;
      let profilePicture = userData.profile_picture?.link;

      if (profilePicture && !this.imageError) {
        return profilePicture;
      }
      return "media/users/blank.png";
    },
    getMenuEntries() {
      let userId = this.$store.getters.userData.id;
      let userType = this.$store.getters.userType;
      switch (userType) {
        case "admin":
          return [
            {
              label: "users.baseData",
              icon: "fal fa-user",
              route: {
                name: this.appVersion < 2 ? "adminAccountUsersDetails" : 'user.profile.information',
                params: { id: userId }
              }
            },
            {
              label: "users.accessData",
              icon: "fal fa-key",
              route: {
                name: this.appVersion < 2 ? "adminAccountUsersDetailsAccessData" : 'user.profile.access-data',
                params: { id: userId }
              }
            }
          ];
        case "client":
          return [
            {
              label: "users.baseData",
              icon: "fal fa-user",
              route: {
                name: "tenantUserAdministrationDetails",
                params: { id: userId }
              }
            },
            {
              label: "users.accessData",
              icon: "fal fa-key",
              route: {
                name: "tenantUserAdministrationDetailsAccessData",
                params: { id: userId }
              }
            }
          ];
        case "partner":
          return []; // Work in progress
        default:
          return [];
      }
    }
  },
  mounted() {
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.expandedSidebarMenu = localStorage.getItem("expanded_menu");
    this.expandedSidebarMenuSet();
  },
  methods: {
    onLogout() {
      Auth.logout();
      this.$router.push({ name: "login" });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    expandedSidebarMenuSet() {
      if (this.expandedSidebarMenu) {
        store.dispatch(REMOVE_BODY_CLASSNAME, "aside-minimize");
        localStorage.setItem("expanded_menu", this.expandedSidebarMenu);
      } else {
        store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
        localStorage.removeItem("expanded_menu");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.navi {
  .navi-item {
    .navi-link {
      transition: 0s;
      .navi-text {
        color: inherit;
      }
    }
  }
}
#kt_quick_user {
  overflow: hidden;
}
</style>
