var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[(_vm.selectedArea.name !== 'areaAdmin' && _vm.userType === 'admin' && _vm.appVersion < 2)?_c('b-dropdown',{staticClass:"select-client-dropdown y-dropdown ml-1 my-1 my-sm-0",class:_vm.targetArea ? 'switcharea-modal-dropdown' : '',attrs:{"size":"md","toggle-class":_vm.selectedPartner
        ? 'select-client-dropdown-btn topbar-item text-decoration-none p-0 truncate-2'
        : '',"variant":"light"},scopedSlots:_vm._u([(_vm.busyPartners)?{key:"button-content",fn:function(){return [_c('span',{staticClass:"text-center"},[_c('b-spinner')],1)]},proxy:true}:{key:"button-content",fn:function(){return [(_vm.selectedPartner)?_c('span',{staticClass:"py-0 px-1 m-0"},[_vm._v(" "+_vm._s(_vm.selectedPartner.name)),_c('br'),_vm._v("#"+_vm._s(_vm.selectedPartner.number)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("topbar.partnerSelect"))+" ")])]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.setPartner(null)}}},[_c('strong',[_vm._v(_vm._s(_vm.$t("topbar.partnerNone"))+" ")])]),(_vm.partners.length > 0)?_c('div',[_c('b-dropdown-divider'),_c('b-form-input',{attrs:{"id":"searchPartners","placeholder":_vm.$t('search.searchTerm')},model:{value:(_vm.searchTitle),callback:function ($$v) {_vm.searchTitle=$$v},expression:"searchTitle"}})],1):_vm._e(),_vm._l((_vm.filteredPartners),function(item,i){return _c('b-dropdown-item',{key:i,attrs:{"value":item.id},on:{"click":function($event){return _vm.setPartner(item)}}},[_c('span',{staticStyle:{"word-wrap":"break-word","white-space":"normal"}},[_vm._v(" "+_vm._s(item.name)),_c('br'),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(" #"+_vm._s(item.number)+" ")])])])})],2):_vm._e(),(
      _vm.selectedArea.name !== 'areaAdmin' &&
      _vm.appVersion < 2 &&
      (_vm.userType === 'admin' || _vm.userType === 'partner')
    )?_c('b-dropdown',{staticClass:"select-client-dropdown y-dropdown ml-1 mb-1 my-sm-0",class:_vm.targetArea ? 'switcharea-modal-dropdown' : '',attrs:{"size":"md","toggle-class":_vm.selectedClient
        ? 'select-client-dropdown-btn topbar-item text-decoration-none p-0 truncate-2'
        : '',"variant":"light"},scopedSlots:_vm._u([(_vm.busyClients)?{key:"button-content",fn:function(){return [_c('span',{staticClass:"text-center"},[_c('b-spinner')],1)]},proxy:true}:{key:"button-content",fn:function(){return [(_vm.selectedClient)?_c('span',{staticClass:"py-0 px-1 m-0"},[_vm._v(" "+_vm._s(_vm.selectedClient.clientName)),_c('br'),_vm._v("#"+_vm._s(_vm.selectedClient.number)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("topbar.clientSelect"))+" ")])]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.setClient(null)}}},[_c('strong',[_vm._v(_vm._s(_vm.$t("topbar.clientNone"))+" ")])]),(_vm.clients.length > 0)?_c('div',[_c('b-dropdown-divider'),_c('b-form-input',{attrs:{"id":"searchClients","placeholder":_vm.$t('search.searchTerm')},model:{value:(_vm.searchTitle),callback:function ($$v) {_vm.searchTitle=$$v},expression:"searchTitle"}})],1):_vm._e(),_vm._l((_vm.filteredClients),function(item,i){return _c('b-dropdown-item',{key:i,attrs:{"value":item.id},on:{"click":function($event){return _vm.setClient(item)}}},[_c('span',{staticStyle:{"word-wrap":"break-word","white-space":"normal"}},[_vm._v(" "+_vm._s(item.name)),_c('br'),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(" #"+_vm._s(item.number)+" ")])])])})],2):_vm._e(),(_vm.selectedArea.name === 'areaProject')?_c('b-dropdown',{staticClass:"select-client-dropdown y-dropdown ml-1 mb-1 my-sm-0",class:_vm.targetArea ? 'switcharea-modal-dropdown' : '',attrs:{"size":"md","toggle-class":_vm.selectedProject
        ? 'select-client-dropdown-btn topbar-item text-decoration-none p-0 truncate-2'
        : '',"variant":"light"},scopedSlots:_vm._u([(_vm.busyProjects)?{key:"button-content",fn:function(){return [_c('span',{staticClass:"text-center"},[_c('b-spinner')],1)]},proxy:true}:{key:"button-content",fn:function(){return [(_vm.selectedProject)?_c('span',{staticClass:"py-0 px-1 m-0"},[_vm._v(" "+_vm._s(_vm.selectedProject.name)),_c('br'),_vm._v("#"+_vm._s(_vm.selectedProject.short)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("topbar.projectSelect"))+" ")])]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.setProject('')}}},[_c('strong',[_vm._v(_vm._s(_vm.$t("topbar.projectNone"))+" ")])]),(_vm.projects.length > 0)?_c('div',[_c('b-dropdown-divider'),_c('b-form-input',{attrs:{"id":"searchProjects","placeholder":_vm.$t('search.searchTerm')},model:{value:(_vm.searchTitle),callback:function ($$v) {_vm.searchTitle=$$v},expression:"searchTitle"}})],1):_vm._e(),_vm._l((_vm.filteredProjects),function(item,i){return _c('b-dropdown-item',{key:i,attrs:{"value":item.id},on:{"click":function($event){return _vm.setProject(item)}}},[_c('span',{staticStyle:{"word-wrap":"break-word","white-space":"normal"}},[_vm._v(" "+_vm._s(item.name)),_c('br'),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(" #"+_vm._s(item.short)+" ")])])])})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }